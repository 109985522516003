<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.order_no" placeholder="请输入订单号" style="width: 250px;" clearable/>
      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 200px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.sp_order_status" placeholder="分账订单状态" style="width: 140px" clearable>
        <el-option label="待分账" :value="1"></el-option>
        <el-option label="处理中" :value="2"></el-option>
        <el-option label="分账完成" :value="3"></el-option>
        <el-option label="分账失败" :value="4"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="60">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_no }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>
<!--      <el-table-column label="订单状态" min-width="80" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <el-tag type="info" v-if="scope.row.order_status == 0">未接单</el-tag>-->
<!--          <el-tag type="info" v-else-if="scope.row.order_status == 1">已接单</el-tag>-->
<!--          <el-tag type="info" v-else-if="scope.row.order_status == 2">待评价</el-tag>-->
<!--          <el-tag type="success" v-else-if="scope.row.order_status == 3">已完成</el-tag>-->
<!--          <el-tag type="danger" v-else-if="scope.row.order_status == 4">已取消</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="分账订单状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.sp_order_status == 1">待分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.sp_order_status == 2">处理中</el-tag>
          <el-tag type="success" v-else-if="scope.row.sp_order_status == 3">分账完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.sp_order_status == 4">分账失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="分账错误原因" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_status===4?scope.row.sp_error_msg:'' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="下单时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="230"
              class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
                  type="success"
                  size="mini"
                  @click="revenueLog(scope.row)"
          >分账收入记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      shops: [],
      list: null,
      total: null,
      btnLoading: false,
      listLoading: true,
      loading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_id: "",
        order_no: "",
        sp_order_status: "",
        start_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.getList();
    this.getShopList();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/school/spOrder",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    revenueLog(item) {
      this.$router.push(`/school/revenueLog?order_no=${item.order_no}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
</style>
